<template>
  <div class="magazine">
    <div class="header">
      <div v-show="!trans" class="header-content">
        <div style="cursor: pointer;display: flex;align-items: flex-end;justify-content: space-between;">
          <div class="title" v-if="doc.Title">{{ doc.Title }}</div>
          <img src="../assets/transZh.png" alt="" @click="transTo"/>
        </div>
        <div class="header-title" v-if="doc.Sponsor">{{ doc.Sponsor }}</div>
        <div class="header-panels">
          <div v-if="doc.Language">
            <span>语种</span>
            <span>英语</span>
          </div>
          <div v-if="doc.SponsorRegion">
            <span>出版地</span>
            <span>{{ region[doc.SponsorRegion.trim()] }}</span>
          </div>
          <div v-if="doc.ISSN">
            <span>ISSN</span>
            <span>{{ doc.ISSN }}</span>
          </div>
          <div v-if="doc.eissn">
            <span>EISSN</span>
            <span>{{ doc.eissn }}</span>
          </div>
          <div v-if="doc.publisher">
            <span>出版社</span>
            <span>{{ doc.publisher }}</span>
          </div>
          <div v-if="doc.IssuedPeriod">
            <span>出版周期</span>
            <span>{{ doc.IssuedPeriod }}</span>
          </div>
          <div v-if="doc.ClassCode">
            <span>分类名</span>
            <span v-for="(item,index) in doc.ClassCode.trim().split(';')">
              {{ classCode[item.trim().substr(1, 4)] || item }}
              {{
                doc.ClassCode.split(';').length - 1 !== index && item.trim() !== '' && item.trim() !== undefined ? '  ' : ''
              }}
            </span>
          </div>
        </div>
      </div>
      <div v-show="trans" class="header-content">
        <div style="cursor: pointer;display: flex;align-items: flex-end;justify-content: space-between;">
          <div class="title" v-if="doc.Title_Translated">{{ doc.Title_Translated }}</div>
          <img src="../assets/transEn.png" alt="" @click="transTo"/>
        </div>
        <div class="header-title" v-if="doc.Sponsor_Translated">{{ doc.Sponsor_Translated }}</div>
        <div class="header-panels">
          <div v-if="doc.Language">
            <span>语种</span>
            <span>英语</span>
          </div>
          <div v-if="doc.SponsorRegion">
            <span>出版地</span>
            <span>{{ region[doc.SponsorRegion.trim()] }}</span>
          </div>
          <div v-if="doc.ISSN">
            <span>ISSN</span>
            <span>{{ doc.ISSN }}</span>
          </div>
          <div v-if="doc.eissn">
            <span>EISSN</span>
            <span>{{ doc.eissn }}</span>
          </div>
          <div v-if="doc.publisher_Translated">
            <span>出版社</span>
            <span>{{ doc.publisher_Translated }}</span>
          </div>
          <div v-if="doc.IssuedPeriod_Translated">
            <span>出版周期</span>
            <span>{{ doc.IssuedPeriod_Translated }}</span>
          </div>
          <div v-if="doc.ClassCode">
            <span>分类名</span>
            <span v-for="(item,index) in doc.ClassCode.trim().split(';')">
              {{ classCodeZh[item.trim().substr(1, 4)] || item }}
              {{
                doc.ClassCode.split(';').length - 1 !== index && item.trim() !== '' && item.trim() !== undefined ? '  ' : ''
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="home">
        <el-container>
          <el-skeleton
              :rows="20"
              :style="'width:100%;min-height: 600px;'"
              :loading="loading"
              animated
              :throttle="500"
          >
            <el-main style="display: flex;flex-flow: column;align-items: center">
              <div
                  style="width: 1200px;min-height: 60px;display: flex;align-items: flex-end;justify-content: space-between;">
                <div
                    style="width: 300px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                  共检索出文章
                  {{ totalCount }}
                </div>
                <div id="facet-select" style="width: 460px;display: flex;white-space: nowrap;overflow: hidden;">
                  <div v-for="(item,index) in filter" style="height: 24px;background: #F5F5F5;margin-right: 5px;">
                    <span
                        style="padding: 0 10px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">{{
                        item.substr(0, item.indexOf(":")) === '期' ? item.substr(item.indexOf(":") + 2).substr(0, item.substr(item.indexOf(":") + 2).length - 1) + '期' : item.substr(item.indexOf(":") + 1)
                      }}</span>
                    <img @click="deleteFact(index)" style="cursor:pointer;padding: 0 10px" width="10" height="9"
                         src="../assets/clear-fact.png" alt="">
                  </div>
                </div>
                <div
                    style="width: 420px;display: flex;justify-content: flex-end;align-items: flex-end;margin-left: 20px;">
                  <div style="height: 25px;display: flex;cursor: pointer" v-show="filter.length>0&&showSelect">
                    <img @click="right" src="../assets/left.png" alt=""/>
                    <img @click="left" style="margin-left:5px;" src="../assets/right.png" alt=""/>
                    <div @click="clearSelect"
                         style="margin-left:5px;display:flex;align-items:center;justify-content:center;width: 40px;height: 24px;background: #F5F5F5;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #074096;">
                      清除
                    </div>
                  </div>
                  <div class="search-input">
                    <el-input
                        @change="toSearch('new')"
                        placeholder="请输入题名、作者"
                        suffix-icon="el-icon-search"
                        v-model="word">
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-show="totalCount>0"
                   style="width: 1200px;height: 1px;background: #DDDDDD;margin-top: 10px;margin-bottom: 20px"></div>
              <div v-show="totalCount>0" style="display: flex;">
                <div style="width:280px" class="aside">
                  <el-aside width="280px" style="background-color: rgb(238, 241, 246)">
                    <el-menu
                        :default-openeds="['出版年']"
                        class="el-menu-vertical-demo">
                      <el-checkbox-group v-model="filter" @change="handleCheckedFactChange">
                        <el-submenu v-for="(v,k) in facetFieldData[0]" :index="k">
                          <template slot="title" style="padding-left: 0">
                      <span
                          style="width: 28px;height: 15px;font-size: 14px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;line-height: 24px;">{{
                          k
                        }}</span>
                          </template>
                          <el-menu-item class="facet-item" v-for="(val,i) in v" :index="(k+'-'+i)">
                            <el-checkbox style="display: flex;"
                                         :label='"年份:"+val.value'>
                              <div style="width: 210px;display: flex;justify-content: space-between">
                                <div style="width: 180px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"
                                     :title="val.value">{{
                                    val.value
                                  }}
                                </div>
                              </div>
                            </el-checkbox>
                            <div class="item-check"
                                 style="margin-top: 10px;display: flex; width: 275px;flex-wrap: wrap;">
                              <div v-for="(item,i) in val.pivot||[]">
                                <el-checkbox @change="addSelect('年份:'+val.value)" style="display: flex;"
                                             :label='"期:\""+item.value+"\""'>
                                  <div
                                      style="padding-left: 23px;padding-bottom:10px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;">
                                    {{ item.value }}期
                                  </div>
                                </el-checkbox>
                              </div>
                            </div>
                          </el-menu-item>
                          <el-menu-item v-show="v.length!==0&&v.length%pageSizeFacet===0"
                                        class="facet-item" :index="k+'1'">
                          <span style="color:#063F95 ;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;"
                                @click="toMore(k,v.length)">更多</span>
                          </el-menu-item>
                        </el-submenu>
                      </el-checkbox-group>
                    </el-menu>
                  </el-aside>
                </div>
                <div style="width: 920px" class="main-content">
                  <div style="display: flex;align-items: center;margin-top: 10px;margin-bottom: 23px; ">
                    <div style="cursor: pointer;display: flex;align-items: center;justify-content: center;margin-left: 750px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                      排序：<span
                        style="color:rgba(6, 63, 149, 1);cursor: pointer">{{ '相关度' }}</span>
                      <span style="padding-left: 20px;color:rgba(6, 63, 149, 1);cursor: pointer">
                    {{ '出版年' }}</span>
                      <div>
                        <div v-show="sortOrder===3"
                             style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                          <img @click="sort(1)"
                               src="../assets/default_up.png" alt=""/>
                          <img @click="sort(0)"
                               src="../assets/default_down.png" alt=""/>
                        </div>
                        <div v-show="sortOrder===0"
                             style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                          <img @click="sort(1)"
                               src="../assets/default_up.png" alt=""/>
                          <img @click="sort(3)"
                               src="../assets/dw.png" alt=""/>
                        </div>
                        <div v-show="sortOrder===1"
                             style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                          <img @click="sort(3)"
                               src="../assets/up.png" alt=""/>
                          <img @click="sort(0)"
                               src="../assets/default_down.png" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-for="item in data" :key="item.id">
                    <el-card class="box-card">
                      <div v-show="!trans" class="text">
                        <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id
                      }}">
                          <div style="display: flex;margin-bottom: 10px;margin-top: 3px;">
                            <div
                                style="width: 800px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;line-height: 30px;margin-top: -7px;"
                                v-html="item.Title"></div>
                            <div v-show="item.Translated_Status||item.Translated_Status==='ok'"
                                 style="height: 15px;display: flex;align-items: center;">
                              <img
                                  src="../assets/yi.png" alt=""/>
                            </div>
                          </div>
                        </router-link>
                        <div v-if="item.Creator"
                             style="color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          作者:
                          <span v-for="(name, index) in item.Creator?item.Creator.split('%%'):[]"
                                :key="index">
                            <span @click="toOther('作者',name)" v-html="name"></span>
                            <span v-if="item.Creator&&index!==item.Creator.split('%%').length-1">&nbsp;&nbsp;</span>
                          </span>
                          <span style="margin-left: 35px;">年，卷（期）：</span>
                          <span v-if="item.PublishYear">{{ item.PublishYear }}，</span>
                          <span v-if="item.Volum">{{ item.Volum }}</span>
                          <span v-if="item.Issue">({{ item.Issue }})</span>
                        </div>
                        <div v-show="item.HasFulltext.toLowerCase()==='y'"
                             style="cursor: pointer;line-height: 40px;justify-content: space-between;margin-top: 10px;display: flex;width: 262px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;line-height: 40px;">
                          <el-dropdown @command="handleOrgCommand($event,item,'org')">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;">
                              下载原文
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="pdf">PDF
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="docx">WORD
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <el-dropdown @command="handleOrgCommand($event,item,'tran')"
                                       v-show="item.Translated_Status&&item.Translated_Status==='ok'">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;">
                              下载译文
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="pdf">PDF
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="docx">WORD
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="commentsone">原文批准
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="commentstwo">译文批准
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <div @click="readView(item)">在线阅读</div>
                          <div @click="transDoc(item)" v-show="!item.Translated_Status||item.Translated_Status!=='ok'">
                            翻译
                          </div>
                        </div>
                      </div>
                      <div v-show="trans" class="text">
                        <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id
                      }}">
                          <div style="display: flex;margin-bottom: 10px;margin-top: 3px;">
                            <div
                                style="width: 800px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;line-height: 30px;margin-top: -7px;"
                                v-html="item.Title_Translated"></div>
                            <div v-show="item.Translated_Status||item.Translated_Status==='ok'"
                                 style="height: 15px;display: flex;align-items: center;">
                              <img
                                  src="../assets/yi.png" alt=""/>
                            </div>
                          </div>
                        </router-link>
                        <div v-if="item.Creator_Translated"
                             style="color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          作者:
                          <span v-for="(name, index) in item.Creator_Translated?item.Creator_Translated.split('%%'):[]"
                                :key="index">
                            <span @click="toOther('作者',name)" v-html="name"></span>
                            <span
                                v-if="item.Creator_Translated&&index!==item.Creator_Translated.split('%%').length-1">&nbsp;&nbsp;</span>
                          </span>
                          <span style="margin-left: 35px;">年，卷（期）：</span>
                          <span v-if="item.PublishYear">{{ item.PublishYear }}，</span>
                          <span v-if="item.Volum">{{ item.Volum }}</span>
                          <span v-if="item.Issue">({{ item.Issue }})</span>
                        </div>
                        <div v-show="item.HasFulltext.toLowerCase()==='y'"
                             style="cursor: pointer;line-height: 40px;justify-content: space-between;margin-top: 10px;display: flex;width: 262px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;line-height: 40px;">
                          <el-dropdown @command="handleOrgCommand($event,item,'org')">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;">
                              下载原文
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="pdf">PDF
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="docx">WORD
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <el-dropdown @command="handleOrgCommand($event,item,'tran')"
                                       v-show="item.Translated_Status&&item.Translated_Status==='ok'">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;">
                              下载译文
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="pdf">PDF
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="docx">WORD
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="commentsone">原文批准
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="commentstwo">译文批准
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <div @click="readView(item)">在线阅读</div>
                          <div @click="transDoc(item)" v-show="!item.Translated_Status||item.Translated_Status!=='ok'">
                            翻译
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </div>
              </div>
              <div v-show="totalCount>0" class="pages"
                   style="width: 1200px;display: flex;justify-content: flex-end;margin-top: 20px;margin-bottom: 40px">
                <el-pagination
                    @prev-click="toPageChange(1)"
                    @next-click="toPageChange(totalCount/pageSize)"
                    prev-text="第一页"
                    next-text="最后一页"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="total, prev, pager, next"
                    :total="totalCount">
                </el-pagination>
              </div>
            </el-main>
            <Empty v-bind:keyList="absData" v-bind:word="word" v-show="!(totalCount>0)"/>
          </el-skeleton>
        </el-container>
      </div>
    </div>
    <div v-if="isShowTransDoc" style="
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 140px;
          right: 0;
          border-radius: 6px;">
      <div class="isShowTransDoc" v-for="(item,index) in JSON.parse(isShowTransDoc)" @click="">
        <div
            style="color: #333333 !important;padding: 14px;">
          <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
            <div style="width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                 v-html="item.Title"></div>
            <img style="cursor: pointer" src="../assets/close.png" alt="" @click="closes(item.id)"/>
          </div>
          <el-progress :percentage="item.number?item.number:0" :format="format">
          </el-progress>
        </div>
      </div>
    </div>
    <div v-if="downLoadList&&downLoadList.length>0" class="downs" style="
    height: 50px;
          z-index: 100;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 10px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <el-progress v-for="(item,index) in  downLoadList" :key="item.id" style="padding-left: 10px;padding-right: 10px"
                     :stroke-width="2" :color="'rgb(6, 63, 149)'"
                     status="success" :width="35" type="circle"
                     :percentage="50">
        </el-progress>
      </div>
    </div>
    <div v-if="isOk" style="
          height: 50px;
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 75px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;width: 100%;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <span>翻译完成<span style="cursor: pointer;color: rgba(95, 138, 202, 1);margin-right: 22px"
                        @click="toList">去查看</span></span>
        <img src="../assets/OK.png" alt=""/>
      </div>
    </div>
    <el-dialog
        :top="'40vh'"
        :show-close="false"
        :visible.sync="setMailVisible"
        width="280px"
        center>
        <span slot="title"
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
           您暂未设置邮箱，请填写邮箱
        </span>
      <div style="width: 100%;display: flex;justify-content: center">
        <div
            style="width: 95px;height: 30px;background: #063F95;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
            <span style="cursor: pointer;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;"
                  @click="toSetMail">
              填写邮箱
            </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchServiceClient,
  documentRequest,
  facetRequest,
  queryRequest,
  downloadRequest,
  transDocRequest, checkStatusRequest
} from '@/tools/search-api'
import merge from "webpack-merge";
import region from "../../region.json"
import classCode from "../../classCode.json"
import classCodeZh from "../../classCodeZh.json"
import Empty from '@/components/Empty'
import jwt from "jwt-decode";

export default {
  name: "MagazineInfo",
  components: {
    Empty
  },
  data() {
    return {
      region: region,
      classCodeZh: classCodeZh,
      classCode: classCode,
      id: '',
      collection: '',
      doc: {},
      facetField: [],
      subFacetField: ["出版年"],
      facetFieldData: [],
      checkList: [],
      pageSize: 10,
      currentPage: 1,
      currentPageFacet: 1,
      pageSizeFacet: 5,
      filter: [],
      word: '',
      select: '所有领域',
      data: [],
      totalCount: 0,
      loading: true,
      isCheck: false,
      showSelect: false,
      sortField: "",
      sortOrder: 3,
      absData: [],
      trans: true,
      downLoadList: [],
      isShowTransDoc: undefined,
      clas: '',
      comment:'',
      downLoadItem: undefined,
      radio: 'pdf',
      transTimer: undefined,
      isOk: false,
      oldToken: '',
      checkLogin: undefined,
      setMailVisible: false,
    }
  },
  created() {
    this.id = this.$route.query.id
    this.collection = this.$route.query.collection
    this.trans = this.$route.query.trans||true
    if (this.id && this.collection) {
      this.document()
    }
    let filter = this.$route.query.filter || []
    if (typeof (filter) === 'string') {
      filter = [filter]
    }
    this.currentPage = this.$route.query.currentPage || 1
    this.pageSize = this.$route.query.pageSize || 10
    this.filter = filter
    this.word = this.$route.query.word
    this.select = this.$route.query.select || '所有领域'
    this.collection = this.$route.query.collection
    this.sortField = this.$route.query.sortField || ''
    this.queryAbs()
    this.toSearch()
    let isShowTransDoc = localStorage.getItem("isShowTransDoc")
    if (isShowTransDoc) {
      this.isShowTransDoc = isShowTransDoc
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    }
  },
  updated() {
    this.showSelect = document.getElementById('facet-select')?.scrollWidth > 650
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`;
    },
    closes(id) {
      let isShowTransDoc = JSON.parse(this.isShowTransDoc)
      let docs = []
      isShowTransDoc.forEach((item, index) => {
        if (item.id === id) {
          return
        }
        docs.push(item)
      })
      if (docs.length === 0) {
        this.isShowTransDoc = undefined
        localStorage.removeItem("isShowTransDoc")
        return
      }
      this.isShowTransDoc = JSON.stringify(docs)
      localStorage.setItem("isShowTransDoc", this.isShowTransDoc)
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    toList() {
      this.isOk = false;
      this.$router.push('/list')
    },
    transTo() {
      this.trans = !this.trans
      this.$router.push({
        query: merge(this.$route.query,
            {
              filter: null
            }
        )
      })
      this.$router.push({
        query: merge(this.$route.query,
            {
              id: this.id,
              select: this.select,
              word: this.word,
              currentPage: this.currentPage,
              pageSize: this.pageSize,
              filter: this.filter,
              sortField: this.sortField,
              trans: this.trans
            }
        )
      })
    },
    addSelect(v) {
      debugger
      let i = -1
      this.filter.forEach((item, index) => {
        if (item === v) {
          i = index
        }
      })
      if (i === -1) {
        this.filter.push(v)
      } else {
        let arr = this.filter.slice(0, i) || []
        let arr2 = this.filter.slice(i + 1, this.filter.length) || []
        arr.push(...arr2)
        this.filter = arr
      }
    },
    onClickSearch(word) {
      this.select = '所有领域'
      this.word = '关键词:\"' + word + '\"'
      this.toSearch('new')
    },
    sort(sortOrder) {
      if (sortOrder === 0 || sortOrder === 1) {
        this.sortField = 'PublishYear'
      } else {
        this.sortField = ''
      }
      this.sortOrder = sortOrder
      this.toSearch()
    },
    right() {
      document.getElementById('facet-select').scrollLeft -= 600
    },
    left() {
      document.getElementById('facet-select').scrollLeft += 600
    },
    clearSelect() {
      this.filter = []
      this.toSearch()
    },
    deleteFact(index) {
      let arr = this.filter.slice(0, index) || []
      let arr2 = this.filter.slice(index + 1, this.filter.length) || []
      arr.push(...arr2)
      this.filter = arr
      this.toSearch()
    },
    handleCheckedFactChange(val) {
      this.currentPage = 1
      this.pageSize = 10
      this.toSearch()
    },
    clearCheck() {
      this.checkList = []
    },
    handleCheckedCitiesChange(val) {
      if (this.checkList.length > 10) {
        this.checkList = this.checkList.slice(0, 10)
        this.$message.warning("暂时只支持批量选择10篇文献")
      }
    },
    handleCheckedAllChange(isCheck) {
      if (this.checkList.length === 10) {
        let checkList = this.checkList
        this.data.forEach(data => {
          checkList.push(data.id)
        })
        checkList = Array.from(new Set(checkList));
        if (checkList.length > 10) {
          if (checkList.length > 10) {
            this.checkList = checkList.slice(0, 10)
          }
          this.$message.warning("暂时只支持批量选择10篇文献")
          return
        }
      }
      this.isCheck = isCheck
      if (isCheck) {
        this.data.forEach(data => {
          this.checkList.push(data.id)
        })
        this.checkList = Array.from(new Set(this.checkList));
      } else {
        this.checkList = []
      }
    },
    handleOrgCommand(command, item, clas) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      this.downLoadList.push(item)
      this.clas = clas
      this.downLoadItem = item
      this.radio = command !== 'pdf' && command !== 'docx' ? 'docx' : command
      this.comment = command
      this.downloadDoc()
    },
    downloadDoc() {
      let request = downloadRequest;
      request.setId(this.downLoadItem.id);
      request.setType(this.radio)
      request.setClasses(this.clas)
      request.setComment(this.comment)
      request.setName(this.downLoadItem.Title)
      const that = this;
      const TOKEN_KEY = 'token';
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let stream = searchServiceClient.download(request, {
        [TOKEN_KEY]: token
      });
      let flag = 0;
      let content = "";
      let title = "";
      let type = "";
      let message = '';
      stream.on('data', (res) => {
        message = res.getMessage()
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() && flag === 0) {
          title = res.getTitle();
          type = res.getType();
        }
        if (!res.getStatus() && flag === 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: res.getMessage()
          });
          that.centerDialogVisible = false;
          return;
        }
        flag++;
        content += res.getContent_asB64();
      });
      stream.on('end', (end) => {
        if (message === 'login') {
          return
        }
        if (message === 'login-person') {
          return;
        }
        let aLink = document.createElement('a');
        let blob = that.base64ToBlob(content, type);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = title;
        aLink.href = window.URL.createObjectURL(blob)
        aLink.click();
        that.centerDialogVisible = false;
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
      });
      stream.on('error', (err) => {
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
        that.centerDialogVisible = false;
        if (err && err.code !== 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: '下载文档失败，请稍后再试！'
          })
        }
      });
    },
    base64ToBlob(code, type) {
      let bstr = atob(code)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: type});
    },
    readView(item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      this.$router.push({
        name: "ReadView",
        query: {
          collection: this.collection,
          id: item.id
        }
      })
    },
    batchTransDoc() {
      if (!this.checkList || this.checkList.length === 0) {
        this.$message.warning("请选择要翻译的文章")
        return
      }
      this.checkList.forEach(item => {
        this.transDoc(item)
      })
    },
    toSetMail() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    transDoc(item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let isShowTransDoc = localStorage.getItem("isShowTransDoc")
      if (isShowTransDoc) {
        let docs = JSON.parse(isShowTransDoc)
        for (let i = 0; i < docs.length; i++) {
          if (!docs[i].id) {
            localStorage.removeItem("isShowTransDoc")
          } else {
            if (docs[i].id === item.id) {
              this.$message.warning("正在翻译啦，请不要着急！")
              return;
            }
          }
        }
        let request = transDocRequest
        request.setId(item.id)
        searchServiceClient.transDoc(request, {
          ['token']: token
        }).then(res => {
          if (res.getStatus() === "login") {
            this.showIframe('/loginTwo?code=haoyi')
            return
          }
          if (res.getStatus() === 'login-person') {
            this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
            this.showIframe('/loginTwo?code=haoyi')
            return;
          }
          if (res.getStatus() === "remote") {
            this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
            return
          }
          if (res.getStatus() === "set_mail") {
            this.setMailVisible = true
            return
          }
          if (res.getStatus() === "fail") {
            this.$message.error("翻译失败，请稍后再试！")
            return
          }
          if (res.getStatus() === "time") {
            this.$message.error("您的机构使用时间已截至,请联系销售人员！")
            return
          }
          if (res.getStatus() === "success") {
            docs.push({
              id: item.id,
              Title: item.Title
            })
            localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
            this.isShowTransDoc = JSON.stringify(docs)
            return
          }
          this.$message.error(res.getStatus())
        }).catch(err => {
          this.$message.error("翻译异常，请稍后再试！")
        })
        return
      }
      let request = transDocRequest
      request.setId(item.id)
      searchServiceClient.transDoc(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus() === "login") {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getStatus() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() === "remote") {
          this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
          return
        }
        if (res.getStatus() === "set_mail") {
          this.setMailVisible = true
          return
        }
        if (res.getStatus() === "fail") {
          this.$message.error("翻译失败，请稍后再试！")
          return
        }
        if (res.getStatus() === "time") {
          this.$message.error("您的机构使用时间已截至,请联系销售人员！")
          return
        }
        if (res.getStatus() === "success") {
          localStorage.setItem("isShowTransDoc", JSON.stringify([{id: item.id, Title: item.Title}]))
          this.isShowTransDoc = JSON.stringify([{id: item.id, Title: item.Title}])
          return
        }
        this.$message.error(res.getStatus())
      }).catch(err => {
        this.$message.error("翻译异常，请稍后再试！")
      })
      clearInterval(this.transTimer)
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    },
    checkStatus() {
      if (this.isShowTransDoc) {
        let docs = JSON.parse(this.isShowTransDoc)
        let isOk = true
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].number === 100) {
            continue
          }
          isOk = false
          let Range = 5 - 1;
          let Rand = Math.random();
          let num = 1 + Math.round(Rand * Range) + parseInt(docs[i].number || 0)
          docs[i].number = num > 99 ? 99 : num
          let request = checkStatusRequest
          request.setDocId(docs[i].id)
          let token = this.$cookies.get('token')
          if (!token) {
            token = localStorage.getItem('token');
          }
          searchServiceClient.checkStatus(request, {
            ['token']: token
          }).then(res => {
            if (res.getStatus()) {
              docs[i].number = 100;
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            } else {
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            }
          }).catch(err => {
            console.log(err)
          })
        }
        if (isOk) {
          clearInterval(this.transTimer)
          localStorage.removeItem("isShowTransDoc")
          this.isShowTransDoc = undefined;
          this.isOk = isOk
        }
      } else {
        clearInterval(this.transTimer)
      }
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    toPageChange(val) {
      this.currentPage = Math.ceil(val)
      this.toSearch()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.toSearch()
    },
    toMore(name, num) {
      let request = facetRequest;
      request.setCurrentPage(num)
      request.setPageSize(this.pageSizeFacet)
      request.setFilterQueryList(this.filter)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection(this.collection)
      if (request.getFacetQueryWord()) {
        let innerFilter = ["PeriodicalId:" + this.id, ...request.getFilterQueryList()]
        request.setFilterQueryList(innerFilter)
      } else {
        request.setFacetQueryWord("PeriodicalId:" + this.id)
      }
      if (name === '出版年') {
        request.setSubFacetFieldList([name])
      } else {
        request.setFacetFieldList([name])
      }
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        let documentJson = JSON.parse(document)
        this.facetFieldData.forEach((item, index) => {
          if (item.name === documentJson[0].name) {
            item.valueCount += documentJson[0].valueCount
            item.values = [...item.values, ...documentJson[0].values]
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    toDocument() {
    },
    isEmpty(val) {
      if (val == null) return true;
      if (typeof val === 'boolean') return false;
      if (typeof val === 'number') return !val;
      if (val instanceof Error) return val.message === '';
      switch (Object.prototype.toString.call(val)) {
        case '[object String]':
        case '[object Array]':
          return !val.length;
        case '[object File]':
        case '[object Map]':
        case '[object Set]': {
          return !val.size;
        }
        case '[object Object]': {
          return !Object.keys(val).length;
        }
      }
      return false;
    },
    facet() {
      let request = facetRequest;
      request.setCurrentPage(this.currentPageFacet)
      request.setPageSize(this.pageSizeFacet)
      request.setFilterQueryList(this.filter)
      request.setDefaultField("作者|题名")
      request.setFacetQueryWord(this.word)
      request.setCollection("Periodical")
      request.setFacetFieldList(this.facetField)
      request.setSubFacetFieldList(this.subFacetField)
      if (request.getFacetQueryWord()) {
        let innerFilter = ["PeriodicalId:" + this.id, ...request.getFilterQueryList()]
        request.setFilterQueryList(innerFilter)
      } else {
        request.setFacetQueryWord("PeriodicalId:" + this.id)
      }
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        this.facetFieldData = JSON.parse(document)
      }).catch(err => {
        console.log(err)
      })
    },
    clearWord() {
      this.word = ""
    },
    queryAbs() {
      let request = facetRequest;
      request.setCurrentPage(1)
      request.setPageSize(5)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection('Periodical')
      request.setFacetFieldList(['关键词'])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          return
        }
        this.absData = JSON.parse(document)
      }).catch(err => {
        console.log(err)
      })
    },
    toOther(sle, val) {
      this.select = sle
      this.word = val
      this.toSearch('new')
    },
    toSearch(val) {
      if (this.word && (this.word === '*' || this.word.toLowerCase() === 'and' || this.word.toLowerCase() === 'or'
          || this.word === '!' || this.word.toLowerCase() === 'not')) {
        this.$message.warning("检索关键词中包含非法字符，请修改后重新检索")
        return
      }
      if (val === 'new') {
        this.queryAbs()
        this.select = "作者|题名"
        this.filter = []
        this.currentPage = 1
        this.pageSize = 10
        this.$router.push({
          query: merge(this.$route.query,
              {
                id: this.id,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: null,
                sortOrder: null,
                sortField: null
              }
          )
        })
      } else {
        this.$router.push({
          query: merge(this.$route.query,
              {
                filter: null
              }
          )
        })
        this.$router.push({
          query: merge(this.$route.query,
              {
                id: this.id,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: this.filter,
                sortField: this.sortField,
                sortOrder: this.sortOrder
              }
          )
        })
      }
      this.facet();
      let request = queryRequest
      request.setCurrentPage(this.currentPage)
      request.setPageSize(this.pageSize)
      request.setFilterQueryList(this.filter)
      request.setSortField(this.sortField)
      request.setSortOrder(this.sortOrder)
      request.setDefaultField("作者|题名")
      request.setQueryWord(this.word)
      request.setCollection("Periodical")
      if (request.getQueryWord()) {
        let innerFilter = ["PeriodicalId:" + this.id, ...request.getFilterQueryList()]
        request.setFilterQueryList(innerFilter)
      } else {
        request.setQueryWord("PeriodicalId:" + this.id)
      }
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      this.loading = true
      let that = this
      searchServiceClient.query(request, {
        ['token']: token
      }).then(res => {
        debugger
        that.loading = false
        let document = res.getDocument()
        if (!document) {
          that.$message.warning("查询为空")
          return
        }
        let parse = JSON.parse(document);
        that.data = parse.documents || []
        that.totalCount = parse.numFound || 0
      }).catch(err => {
        debugger
        that.totalCount = 0
        that.loading = false
        console.log(err)
        this.$message.warning("查询失败")
      })
    },
    document() {
      let request = documentRequest;
      request.setId(this.id)
      request.setCollection(this.collection)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.document(request, {
        ['token']: token
      }).then(res => {
        this.doc = JSON.parse(res.getDocument())[0]
      }).catch(err => {
        console.log(err)
      })
    }
  },
  destroyed() {
    clearInterval(this.transTimer)
    clearInterval(this.checkLogin)
  }
}
</script>
<style lang="less">
.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  .el-select-dropdown__item.selected {
    color: #063F95;
    font-weight: 700;
  }
}
</style>
<style lang="less" scoped>
.magazine {
  width: 100%;
  display: flex;
  flex-flow: column;

  /deep/ .isShowTransDoc {
    .el-progress-bar {
      padding-right: 50px;
      width: 117%;
      margin-right: -54px;
      box-sizing: border-box;
    }

    .el-progress__text {
      margin-top: -35px;
      margin-left: -55px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #5F8ACA;
    }
  }

  /deep/ .downs {
    .el-icon-check:before {
      content: url('');
      display: block;
      width: 14px;
      height: 14px;
      background: url('../assets/downico.png');
      background-size: cover;
    }
  }

  .header {
    width: 100%;
    background: #F5F5F5;
    display: flex;
    justify-content: center;

    .header-content {
      width: 1200px;
      display: flex;
      flex-flow: column;


      .title {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #063F95;
        margin-top: 52px;
      }

      .header-title {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(102, 102, 102, 1);
        margin-top: 20px;
      }

      .header-panels {
        display: flex;
        flex-wrap: wrap;
        margin-top: 23px;
        margin-bottom: 30px;

        div {
          width: 50%;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
          display: flex;

          span:nth-child(1) {
            display: flex;
            width: 70px;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    background: rgba(255, 255, 255, 1);

    .home {
      display: flex;
      width: 100%;
      flex-flow: column;

      /deep/ .aside {
        .el-icon-arrow-down:before {
          content: url("../assets/sanjiao.png");
        }

        .el-menu {
          border: 0;
          overflow-x: hidden;
        }

        .el-menu::-webkit-scrollbar {
          width: 4px;
        }

        .el-menu::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
        }

        .el-menu::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);

        }

        .el-submenu .el-menu {
          overflow-y: auto;
          max-height: 300px;
          width: 275px;
          overflow-x: hidden;
        }

        .el-checkbox__label {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          display: flex;
          align-items: center;
        }

        .el-menu-item {
          min-height: 50px;
          display: contents;
          line-height: 30px;
        }

        .el-menu-item:hover {
          background: white;
        }

        .el-submenu__title:hover {
          background: white;
        }

        .el-submenu__title {
          padding-left: 0 !important;

          .el-submenu__icon-arrow {
            right: 0;
          }
        }

        .el-checkbox__inner {
          width: 19px;
          height: 19px;
          background: #F5F5F5;
          border: 0px solid #DDDDDD;
          border-radius: 9px;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__input {
          width: 19px;
          height: 19px;
          background: #F5F5F5;
          border-radius: 9px;
          border: 1px solid #DDDDDD;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__inner::after {
          background: #F5F5F5;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          transform: rotate(45deg) scaleY(0);
        }

        .el-checkbox__input.is-checked .el-checkbox__inner {
          border-color: #063F95;
          width: 9px;
          height: 9px;
          background: #063F95;
          border-radius: 4px;
        }
      }

      /deep/ .main-content {
        .box-card {
          margin-bottom: 17px;
        }

        .el-card {
          width: 890px;
          min-height: 186px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          margin-left: 25px;

          .el-menu {
            border: 0;
          }

          .el-card__body {
            padding-top: 20px;
            padding-left: 10px;

            .el-submenu__title:hover {
              background: white;
            }

            .el-submenu__title {
              padding-left: 0 !important;
              width: 100px;

              .el-icon-arrow-down:before {
                content: url("../assets/sanjiao.png");
              }

            }

            .el-checkbox__input {
              width: 24px;
              height: 24px;
              background: #FFFFFF;
              border: 1px solid #DDDDDD;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .el-checkbox__inner {
              width: 24px;
              height: 24px;
              background: #FFFFFF;
              border: 0px solid #DDDDDD;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .el-checkbox__input.is-checked .el-checkbox__inner::after {
              transform: rotate(45deg) scaleY(0);
            }

            .el-checkbox__input.is-checked .el-checkbox__inner {
              border-color: #063F95;
              width: 14px;
              height: 14px;
              background: #063F95;
            }
          }
        }
      }

      /deep/ .el-container.is-vertical {
        align-items: center;
        width: 100%;
      }

      /deep/ .el-header {
        padding: 0;
      }

      /deep/ .el-main {
        padding: 0;
        background: white;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      /deep/ .el-select .el-input {
        width: 235px;
      }

      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }

      /deep/ .check-source {
        padding-top: 23px;
        margin-top: -10px;
        background: #F5F5F5;

        .el-radio__inner {
          cursor: pointer;
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          background: #F5F5F5;
          border: 1px solid #063F95;
          border-radius: 50%;
        }

        .el-radio__inner::after {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: #063F95;
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
        }

        .el-radio__input.is-checked + .el-radio__label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 56px;
        }

        .el-radio__label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 56px;
        }
      }

      /deep/ .search-input {
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        margin-top: 20px;
        width: 400px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-end;

        .el-input {
          width: 300px;
          height: 39px;
          background: #F5F5F5;
        }

        .el-input--suffix .el-input__inner {
          width: 280px;
          height: 39px;
          background: #F5F5F5;
          border: 0;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 56px;
          text-overflow: ellipsis;
        }

        .el-input--suffix .el-input__inner::-webkit-input-placeholder {
          color: #999999;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 56px;
        }

        .el-input__suffix-inner {
          height: 30px;
        }

        .el-icon-search:before {
          content: url("../assets/search.png");
        }

        .el-select .el-input .el-select__caret.is-reverse {
          transform: rotateZ(180deg);
        }

        .el-select .el-input .el-select__caret {
          transform: rotateZ(0);
        }

        .btn-search {
          width: 142px;
          height: 68px;
          background: #1065B1;
          border-radius: 0;

          span {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
          padding-right: 16px;
          cursor: pointer;
        }

        .el-icon-arrow-up:before {
          content: url("../assets/sanjiao.png");
        }

        .el-input-group {
          height: 68px
        }

        .el-input-group--append .el-input__inner, .el-input-group__prepend {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height: 68px;
          border: 0;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }

        .el-input-group__prepend {
          border: 0;
          background: white;
          width: 235px;
          padding-right: 0;
          border-right: 1px solid #DDDDDD;

          .el-input__inner {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 56px;
          }
        }
      }

      /deep/ .pages {
        .el-pagination {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;

          .el-pagination__total {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }

          .btn-prev {
            font-size: 14px;
          }

          .btn-next {
            font-size: 14px;
          }

          .el-pager li {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            background: #F5F5F5;
            margin: 0 5px;
          }

          .el-pager li.active {
            background: #063F95;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }

      a {
        text-decoration: none;
      }

      .text {
        white-space: normal;
        text-align: justify;
        margin-left: 16px;
      }

      .facet-item {
        padding-left: 0px !important;
        display: flex;
        align-items: center;

        /deep/ .item-check {
          .el-checkbox__input {
            display: none;
          }

          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: rgba(6, 63, 149, 1);
          }

          .el-checkbox__label {
            color: #333333;
          }
        }
      }
    }
  }

}

</style>