import { render, staticRenderFns } from "./MagazineInfo.vue?vue&type=template&id=311cc328&scoped=true&"
import script from "./MagazineInfo.vue?vue&type=script&lang=js&"
export * from "./MagazineInfo.vue?vue&type=script&lang=js&"
import style0 from "./MagazineInfo.vue?vue&type=style&index=0&lang=less&"
import style1 from "./MagazineInfo.vue?vue&type=style&index=1&id=311cc328&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311cc328",
  null
  
)

export default component.exports